  <template>
    <f7-list no-hairlines-md >
    <f7-list-input
      label="Фамилия"
      type="text"
      placeholder="Введите фамилию"
      :value= lastName_local
      @input="lastName_local = $event.target.value"
      required
      validate
      v-on:input="onChange"
      clear-button
    >     
    <f7-icon ios="f7:person" md="material:account_circle" slot="media"></f7-icon>
    <span class="input-clear-button"></span>
    <span class="test">erer</span>
    </f7-list-input>

    <f7-list-input
      label="Имя"
      type="text"
      placeholder="Введите имя"
      :value= name_local
      @input="name_local = $event.target.value"
      required
      validate
      :onValidate="(isValid) => setInputValid(isValid)"
      v-on:input="onChange"
      clear-button
    > <f7-icon ios="f7:person" md="material:account_circle" slot="media"></f7-icon>
    </f7-list-input>

    <f7-list-input
      label="Отчество"
      type="text"
      placeholder="Введите отчество"
      :value= patronymic_local
      required
      validate
      clear-button
      @input="patronymic_local = $event.target.value"
      v-on:input="onChange"      
    > 
    <f7-icon ios="f7:person" md="material:account_circle" slot="media"></f7-icon>
    </f7-list-input>

    <f7-list-input v-for="(c,index) in phones " 
      :key = index
      label="Тел."
      placeholder="89xxxxxxxxx,3xxx,4xxx"
      :value= c.field                                
      @input="c.field = $event.target.value"
      v-on:input="onChange"
    >
    <f7-icon ios="f7:phone" md="material:phone" slot="media"></f7-icon>                                                                 
    </f7-list-input>

    <f7-list-input
      label="Пол"
      type="select"
      placeholder="Укажите пол"
      :value= gender_local
      @input="gender_local = $event.target.value"
      v-on:input="onChange"
    > 
    <f7-icon ios="f7:person" md="material:wc" slot="media"></f7-icon>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
    </f7-list-input>
   

    <f7-list-input
      label="e-mail"
      type= "email"
      placeholder="e-mail"
      :value= email_local
      validate
      @input="email_local = $event.target.value"
      clear-button
      v-on:input="onChange"
    > 
    <f7-icon ios="f7:at" md="material:mail_outline" slot="media"></f7-icon>
    </f7-list-input>

    <f7-list-input
      label="Должность"
      type="textarea"
      placeholder="Введите должность"
      :value= position_local
      resizable
      @input="position_local = $event.target.value"
      v-on:input="onChange"
    > 
    <f7-icon ios="f7:text_justify" md="material:texture" slot="media"></f7-icon>
    </f7-list-input>
 
    <f7-list-input
      label="Площадка"
      type="select"
      placeholder="Площадка"
      :value= id_area_local
      @input="id_area_local = $event.target.value"
      v-on:input="onChange"
    >
       <f7-icon ios="f7:info_circle" md="material:info" slot="media"></f7-icon>
       <option v-for="areas in this.$store.getters.AREA" :key='areas.id'  :value='areas.id'>{{areas.name_area}}</option>
    </f7-list-input>

    <f7-list-input
      label="Отдел"
      type="select"
      placeholder="Отдел"
      :value= id_department_local
      @input="id_department_local = $event.target.value"
      v-on:input="onChange"
    >
       <f7-icon ios="f7:info_circle" md="material:info" slot="media"></f7-icon>
       <option v-for="departments in this.$store.getters.DEPARTMENT" :key='departments.id'  :value='departments.id'>{{departments.name_department}}</option>
    </f7-list-input>


</f7-list>
</template>

<script>



module.exports ={
    props: ['name','lastName','patronymic','gender','email','position','id_area','id_department','phones'],
     
    data:function(){
        return{
            lastName_local : this.lastName,
            name_local : this.name,
            patronymic_local : this.patronymic,
            gender_local : this.gender,
            email_local : this.email,
            position_local : this.position,
            id_area_local : this.id_area,
            id_department_local : this.id_department,
            phones_local : this.phones
       }        
    },
    methods:{
     onChange: function(){
            this.$emit('update:name', this.name_local)
            this.$emit('update:lastName', this.lastName_local)
            this.$emit('update:patronymic', this.patronymic_local)
            this.$emit('update:gender', this.gender_local)
            this.$emit('update:email', this.email_local)
            this.$emit('update:position', this.position_local)
            this.$emit('update:id_area', this.id_area_local)
            this.$emit('update:id_department', this.id_department_local)
            this.$emit('update:phones', this.phones_local)
     }
    }

}
</script>
