<template>
<f7-app :params="f7params" >

  <!-- Left panel with cover effect-->
  <f7-panel left cover theme-dark>
    <f7-view>
      <f7-page>
        <f7-navbar :swipe="true" title=""></f7-navbar>
        <f7-block>
        </f7-block>
      </f7-page>
    </f7-view>
  </f7-panel>

  <!-- Right panel with reveal effect-->
  <f7-panel right reveal theme-dark>
    <f7-view>
      <f7-page>
        <f7-navbar title="Right Panel"></f7-navbar>
        <f7-block>Right panel content goes here</f7-block>
      </f7-page>
    </f7-view>
  </f7-panel>
    
  <!-- Views/Tabs container -->
  <f7-views tabs class="safe-areas">
    <!-- Tabbar for switching views-tabs -->
    <f7-toolbar tabbar labels bottom>
      <f7-link  tab-link="#view-pb" icon-ios="f7:person_circle_fill" icon-aurora="f7:person_circle_fill" icon-md="material:person_circle_fill" text="Контакты"></f7-link>
      <f7-link  @click="profile_edit" icon-ios="f7:gear_alt" icon-aurora="f7:settings" icon-md="material:settings" text="Профиль"></f7-link>      
      <f7-link tab-link="#exit" @click="logout" icon-ios="f7:square_arrow_left" icon-aurora="f7:exit_to_app" icon-md="material:exit_to_app" text="Выход"></f7-link>
    </f7-toolbar>  
    <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
    <f7-view id="view-home" main tab tab-active url="/"></f7-view>
    <!-- Catalog View -->
    <f7-view id="view-catalog" name="catalog" tab url="/catalog/"></f7-view>
    <!-- Settings View -->
    <f7-view id="view-pb" name="phonebook" tab url="/phonebook/"></f7-view>
    <f7-view id="view-login" name="login" tab url="/login/"></f7-view>
  </f7-views>

  <!-- Popup -->
  <f7-popup id="my-popup">
    <f7-view>
      <f7-page>
        <f7-navbar title="Popup">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <p>Popup content goes here.</p>
        </f7-block>
      </f7-page>
    </f7-view>
  </f7-popup>

  <f7-login-screen id="my-login-screen" :opened = $store.state.login_open>
    <f7-view>
      <f7-page login-screen>        
        <f7-login-screen-title>Login</f7-login-screen-title>
        <f7-list form>
          <f7-list-input          
            type="text"
            name="username"
            placeholder="Тел."
            :value="username"
            @input="username = $event.target.value"
          >
          </f7-list-input>  
         
          <f7-list-input
            type="password"
            name="password"
            placeholder="Код."
            :value="password"
            @input="password = $event.target.value"
          > 
          </f7-list-input>
        </f7-list>
        <f7-list>
          <f7-list-button title="Sign In" @click="login"></f7-list-button>
          <f7-block-footer>
            <p><a class="button popup-open" href="#" data-popup=".popup-about">Забыли пароль?</a></p>
            <div class="popup popup-about"> 
             <f7-block strong>
               <f7-block-header></f7-block-header>
               <f7-list inset>
                <f7-list-input
                type="text"
                required validate pattern="89[0-9]{9}" data-error-message="Only numbers please!"
                placeholder="89 * * * * * * * * *"
                clear-button
                :value="res_number"
                @input="res_number = $event.target.value"
                >
                <f7-icon ios="f7:phone" md="material:phone" slot="media"></f7-icon> 
                </f7-list-input>                
             </f7-list>
             <div class="row">
                  <div class="col"></div>
                  <div class="col"> 
                    <f7-col>
                      <f7-button class="link popup-close" href="#">Close</f7-button>
                    </f7-col>     
                  </div>
                  <div class="col"><f7-button fill  @click="restore">OK</f7-button></div>                 
                </div>
               <f7-block-footer>                
                 <p>Воспользуйтесь данной формой для восстановления пароля. Введите ваш номер телефона в указанном формате. Подтвердите действие. Код будет выслан SMS-сообщением.</p>
               </f7-block-footer>
             </f7-block>            
            </div>            
          </f7-block-footer>
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-login-screen>
</f7-app>
</template>


<script>

  import routes from '../js/routes.js';  
  export default {   
     data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'Phonebook', // App name
          theme: 'auto', // Automatic theme detection
          // App root data
          data: function () {
            return {
              //loginScreenOpened=false,
              contacts: []
            };
          },

          // App routes
          routes: routes,
          // Register service worker
          serviceWorker: {
            path: '/service-worker.js',
          },
        },

        // Login screen data
        username: '',
        password: '',
        res_number: ''       
      }
    },

    methods: {
      profile_edit: function(){
          this.$f7.views.main.router.navigate(`/edit-contact/${localStorage.id_user}/`)
      },
      login: function () {
        var md5 = require('md5');
        let username = this.username
        let password = md5(this.password)
        this.$store.dispatch('login', { username, password}).then((response) => { 
                                       
                     });  
                        
      },
      restore: function(){
        let res_number = this.res_number           
        this.$store.dispatch('restore', {res_number}).then((response) => { 
                     this.$f7ready((f7) => {
                        window.$f7 = f7; 
                        var notificationFull = this.$f7.notification.create ({
                           title: response,                          
                           closeTimeout: 3000,
                        })
                      notificationFull.open();                      
                     });  
                     }).catch((error) => {
                       console.log(error);
                     });                 
      },
      logout: function () {
        localStorage.removeItem('token')
        this.$store.state.login_open=true 
      },    
    },

    mounted() {      
      this.$f7ready((f7) => {
            window.$f7 = f7;
      }); 
    },
  }
</script>