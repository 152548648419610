
// Import Vue
var tthis;
import Vue from 'vue';

import {store} from './store';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';

// Import App Component
import App from '../components/app.vue';


// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);



 
// Init App
new Vue({
  el: '#app',
  store,
  render: (h) => h(App),
  hideToolbarOnPageScroll: true,  
  mounted() {  
       this.$store.dispatch('GET_CONTACTS').then((response) => {
       this.$store.state.showPreloader = false
       this.$store.state.number_of_contact = response.length
     }), 
     tthis = this
  },

  // Register App Component
  components: {
    app: App     
  },
  
  
});







