<template>
  <f7-page
   name="contacts"
    infinite
    :infinite-preloader= this.$store.state.showPreloader
    :infinite-distance="50"    
  >    
    
    <f7-navbar :sliding="true"  title="Контакты">
      <f7-nav-right>
        <f7-link   @click="$f7router.navigate(`/filter/`)"  icon-ios="f7:menu" icon-md="material:menu" >
          <f7-badge v-bind:color= colorFilter>{{this.$store.state.number_of_contact}}</f7-badge>
        </f7-link>
      </f7-nav-right>  
    </f7-navbar>
    <f7-searchbar placeholder='Поиск' disableButtonText = 'Отменить' 
                  search-container=".contacts-list"
                  >
        <f7-chip text="Добавить контакт"  @click="$f7router.navigate(`/add-contact/${new_id()}/`)"  media-bg-color="blue">
          <f7-icon slot="media" ios="f7:person_badge_plus" aurora="f7:plus_circle" md="material:person_add"></f7-icon>
        </f7-chip>  
    </f7-searchbar>
    <f7-list class="searchbar-not-found">
        <f7-list-item title="Контакт не найден"></f7-list-item>
    </f7-list>  
     <f7-list class="contacts-list" >            
        <f7-list-item  swipeout v-for="(c) in contactsList"            
            v-bind:title="(c.lastName == null ? '':c.lastName) + ' ' + (c.firstName == null ? '':c.firstName) "  
            v-bind:key="c.contactId"            
            v-bind:header="(c.position == null ? '':c.position)"
            v-bind:footer="list_phone(c.phones)"
            :style="`padding: 0px`"
            :after = "(c.owner==myId ? 'Edit':null)"
            :link="`/contact/${c.contactId}/`"                                              
        > 
        <!-- <f7-icon ios="f7:pencil" md="material:edit" slot="media"></f7-icon>  -->
        <f7-swipeout-actions right>                     
          <f7-swipeout-button v-if = "c.owner==myId"   @click="$f7router.navigate(`/edit-contact/${c.contactId}/`)" color="orange">edit</f7-swipeout-button>          
          <f7-swipeout-button v-if = "c.owner==myId" delete  @click="delete_item(c.contactId)" >delete</f7-swipeout-button>
        </f7-swipeout-actions>    
        </f7-list-item>
     </f7-list>  
  </f7-page>
</template>

<script>
  
  export default {
  
  methods:{   
      list_phone: function(phone_array){
        let list=''  
        for (let i = 0;  i < phone_array.length;i++){
          if (i>0){list = list + ','}
          list = list + phone_array[i].field          
        }
        return list
      },

      delete_item: function(id){   
        this.$store.dispatch("deleteItem", id).then((response) => {
                     this.$f7ready((f7) => {
                        window.$f7 = f7; 
                        var notificationFull = this.$f7.notification.create ({
                           title: response,
                           closeTimeout: 3000,
                        })
                      notificationFull.open(); 
                     });  
                     }).catch((error) => {
                       alert(error);
                     });                
      },
      new_id: function(){
         let newid = 0;
         for (let i=0; i<this.$store.getters.CONTACTS.length; i++){         
           if(i==0){
                newid = this.$store.getters.CONTACTS[i]['id'];
           }  
           else{
             if(this.$store.getters.CONTACTS[i]['id']>newid){newid=this.$store.getters.CONTACTS[i]['id']}
           }                   
         }
         let phones = [{'field': ''}]
         let data = {"Date_of_Birth": "",
                     "address": "","contactId":  (newid+1).toString(),
                      "email": "","firstName": "",
                      "gender": "","id": (newid+1).toString(),
                      "id_area": "7","id_department": "54",
                      "lastName": "","name_area": "",
                      "name_department": "","owner": "",
                      "patronymic": "","phones": phones,"position": ""}
         this.$store.getters.CONTACTS.push(data);
         return(newid+1);         
      }
    },  
    computed: {
    myId(){
      return(localStorage.id_user)
    },  
    colorFilter(){
      let color      
      if (this.$store.state.filter_departments.length>0 | this.$store.state.filter_areas.length>0){      
         color = "red"
      } else {
         color = "green"
      }   
      return(color)
    },  
    contactsList() {
          let data = this.$store.getters.CONTACTS
          let id_area = this.$store.state.filter_areas.map(x=>+x)
          let id_department = this.$store.state.filter_departments.map(x=>+x)
          if (this.$store.state.showPreloader == false){
          if (id_area.length == 0 & id_department.length == 0){
             this.$store.state.number_of_contact = data.length 
             return data
          }
          if (id_area.length > 0 & id_department.length == 0){
             let arr = Object.keys(data).filter(k=> id_area.includes(data[k].id_area)).map(k=>data[k]);
             this.$store.state.number_of_contact = arr.length 
             return arr
          }
          if (id_area.length == 0 & id_department.length > 0){
             let arr = Object.keys(data).filter(k=> id_department.includes(data[k].id_department)).map(k=>data[k]);
             this.$store.state.number_of_contact = arr.length   
             return arr
          }
          if (id_area.length > 0 & id_department.length > 0){
             let arr = Object.keys(data).filter(k=> id_area.includes(data[k].id_area) & id_department.includes(data[k].id_department)).map(k=>data[k]);
             this.$store.state.number_of_contact = arr.length 
             return arr
          }
          }            
    }
    }

    // data: function () {
    //   return {
    //     contacts: this.$f7.data.products,
    //   };
    // },
    // mounted(){
    //   this.my_id = localStorage.id_user 
    // } 
  };
</script>
