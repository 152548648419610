<template>
  <f7-page name="EditContact">
    <f7-navbar title="Редактирование" back-link="Назад">
    </f7-navbar>
    <f7-block strong>
    <f7-row>  
    <formListInput 
           :lastName.sync=lastName 
           :name.sync=name
           :patronymic.sync=patronymic
           :gender.sync=gender
           :email.sync=email
           :position.sync=position
           :id_area.sync=id_area
           :id_department.sync=id_department 
           :phones=phones
           >     
  </formListInput>  

  </f7-row> 
     <button class="col button open-alert" @click="editItem">Ок</button>    
    </f7-block>
  </f7-page>   
</template>
<script>


import formListInput from '../components/form-list-input'

export default {
    components: {
        formListInput
    },
    
methods: { 
   
     editItem: function () {
          this.$store.getters.CONTACTS[this.current_Item].firstName = this.name 
          this.$store.getters.CONTACTS[this.current_Item].lastName = this.lastName
          this.$store.getters.CONTACTS[this.current_Item].patronymic = this.patronymic
          this.$store.getters.CONTACTS[this.current_Item].gender = this.gender
          this.$store.getters.CONTACTS[this.current_Item].phones = this.phones
          this.$store.getters.CONTACTS[this.current_Item].Date_of_Birth = this.Date_of_Birth
          this.$store.getters.CONTACTS[this.current_Item].position = this.position
          this.$store.getters.CONTACTS[this.current_Item].email = this.email
          this.$store.getters.CONTACTS[this.current_Item].id_area = this.id_area
          this.$store.getters.CONTACTS[this.current_Item].id_department = this.id_department
          this.$store.dispatch("updateItem",this.$store.getters.CONTACTS[this.current_Item]).then((response) => {
                     this.$f7ready((f7) => {
                        window.$f7 = f7; 
                        var notificationFull = this.$f7.notification.create ({
                             text: response,
                             closeTimeout: 2000,
                        })
                      notificationFull.open(); 
                     });  
                     }).catch((error) => {
                       console.log(error);
                     });     
      }
  }, 
   
   
  data: function(){        
        var contactId = this.$f7route.params.id;
        var cl = this.$store.getters.CONTACTS;
        var current;       
        for (var c in cl){
           if (contactId === cl[c].contactId){
               current = c;
               break;            
           }  
        }
       
      return {        
        current_Item : current,
        name: cl[current].firstName, 
        lastName: cl[current].lastName,
        patronymic: cl[current].patronymic,
        gender: cl[current].gender,
        phones: cl[current].phones,
        Date_of_Birth: cl[current].Date_of_Birth,
        position: cl[current].position,
        email: cl[current].email,
        id_area:  cl[current].id_area,
        id_department: cl[current].id_department
      }
    }
};

</script>