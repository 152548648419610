import Vue from 'vue';
import Vuex from 'vuex';
import Axios from 'axios';
import Dom7 from 'dom7';
import { f7, f7ready} from 'framework7-vue';

var $$ = Dom7;
Vue.use(Vuex);

Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
const urls =  "https://contact.gkmmk.ru/api"
// const urls =  "http://172.40.0.45:8083"
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

export const store = new Vuex.Store({
  state: {
      number_of_contact: null,
      showPreloader: true,   
      login_open: false, 
      status: '',
      token: localStorage.getItem('token') || '',
      contacts: null,
      filter_areas: [],
      filter_departments: [] 
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    CONTACTS: state => {
          return state.contacts;
    },
    DEPARTMENT: state => {
      return state.list_department;
    },
    AREA: state => {
      return state.list_area;
    }

  },
  mutations: {
      auth_request(state){
        state.status = 'loading'
      },
      auth_success(state, token, user){
        state.status = 'success'
        state.token = token
        state.user = user
        window.location.reload(true)        
      },
      auth_error(state){
        state.status = 'error'
      },
      SET_CONTACTS: (state, payload) => {
          state.contacts = payload;
      },
      SET_DEPARTMENT: (state, payload) => {
        state.list_department = payload;
      },
      SET_AREA: (state, payload) => {
        state.list_area = payload;
      },
      
  },
  actions: {

  login({commit}, user){
    return new Promise((resolve, reject) => {      
      commit('auth_request')
      Axios({url:  urls + '/auth', data: user, method: 'POST' })
      .then(resp => {
        const token = resp.data.access_token        
        const user_id = resp.data.user_id
        localStorage.setItem('token', token)
        localStorage.setItem('id_user',user_id)
        Axios.defaults.headers.common['Authorization'] = token
        commit('auth_success', token, user_id )
        resolve(resp)
        store.state.login_open=false
        status = resp.data
        store.state.showPreloader = false                
      })
      .catch(err => {
        commit('auth_error')
        localStorage.removeItem('token')       
        f7ready((f7) => {
          var notificationFull = f7.notification.create ({
             title: 'Ошибка авторизации.',
             closeTimeout: 3000,
          })
        notificationFull.open(); 
       });            
      })
    })
  },

  updateItem: async (context, payload,error) => {
    try{
      let status=''
      Axios.defaults.headers.common['Authorization'] =  'JWT ' + token 
      let res = await Axios.put(urls+'/phone', payload)
      .then ( function (response){   
        status = response     
      })
      return(status.data)     
    } catch (err){
        return(err)
    }
  },
  
  addItem: async (context, payload,error) => {
    try{
      let status=''
      Axios.defaults.headers.common['Authorization'] =  'JWT ' + token 
      let res = await Axios.post(urls+'/phone', payload)
      .then ( function (response){   
        status = response     
      })
      return(status.data)  
    } catch (err){
        return(err)
    }
  },

  deleteItem: async (context, payload,error) => {
    try{  
      let status=''  
      Axios.defaults.headers.common['Authorization'] =  'JWT ' + token
      let res = await Axios.delete(`${urls}/phone/${payload}`)
      .then ( function (response){   
        status = response     
      })
      return(status.data) 
    } catch (err){
        return(err)
    }
  },
  
  restore: async ({commit},res_number)=>{
      let status          
      await Axios({url: urls+'/registration', params: res_number, method: 'POST' })
      .then ( function (response){   
         status = response     
      })
      .catch ( function (error){
         console.log('error'); 
      });
      return(status.data)        
  },
 
  GET_CONTACTS: async (context, payload,error) => {      
      try {      
        const params = {
          filter_id: localStorage.id_user,
        };    
        Axios.defaults.headers.common['Authorization'] =  'JWT ' + token           
        let data = await Axios.get(urls+'/phone',{params});
        context.commit('SET_CONTACTS', data.data.contacts);
        context.commit('SET_DEPARTMENT',data.data.list_department);
        context.commit('SET_AREA',data.data.list_area)
        return(data.data.contacts)  
      } catch (err) {
        context.commit('auth_error')
        localStorage.removeItem('token')
        store.state.login_open=true
      }
  }
  }
});