<template>
  <f7-page name="contact">
    <meta name="format-detection" content="telephone=yes">
    <f7-navbar :title="(contact.firstName == null ? '':contact.firstName) + ' ' + (contact.patronymic == null ? '':contact.patronymic)" back-link="Назад"></f7-navbar>
    <f7-block-title  v-if = "contact.id_area != '7' | contact.id_department !='54'">{{(contact.name_area == null ? '':contact.name_area) + '. ' +  (contact.name_department == null ? '':contact.name_department)}}</f7-block-title>
    <f7-list>
        <f7-list-item v-for="c in contact.phones" 
                    v-bind:key="c.field"
                    v-bind:href="true"
                    v-bind:footer="c.label"  
                    v-bind:title="c.field">
                         <a class="link external icon-only button button-fill-md button-large-md color-green" 
                             v-bind:href = "'tel:'+ c.field"  target="_system">
                             <i class="icon material-icons">phone</i>
                         </a>                                                                                       
        </f7-list-item > 
    </f7-list>
 <!-- v-on:click="call(c.field)" -->
  </f7-page>
</template>

<script>
function iOS() {

  var iDevices = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ];

  if (!!navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()){ return true; }
    }
  }

  return false;
}
  export default {
    computed: {
        contactList() {
            // return this.$store.getters.CONTACTS;
        },
    },
    methods: {
        call: function(num) {        
            // if (iOS() == true){
            //     this.$f7.dialog.create({
            //         title: 'Через что звонить?',
            //         buttons: [
            //         {
            //             text: 'GSM' ,
            //         },
            //         {
            //             text: '3CX',
            //         },
            //         ],
            //         onClick: function (d,i) {
            //             if (i == 0){
            //                 window.location = 'tel:' + num;
            //             } else if (i == 1){
            //                 window.location = 'tcxcallto://' + num;
            //             }
            //             },
            //         verticalButtons: true,
            //         closeByBackdropClick: true,
            //     }).open();
            // } else {
                window.location = 'tel:' + num;
            // }
        }
    },
    data: function () {
      
      var contactId = this.$f7route.params.id;
      var current;
      var cl = this.$store.getters.CONTACTS;      
      for (var c in cl){
          if (contactId === cl[c].contactId){
              current = cl[c];
              break;
          }
      }
    
      return {
        contact: current,
      };
    }
  };
</script>
