<template>
  <f7-page name="FilterPage">
    <f7-navbar title="Фильтр" back-link="Назад">
    </f7-navbar>
     <f7-list>

  <f7-list-item title="Площадка" id='area'  back-link="Назад"  class="item-link smart-select  smart-select-init"  data-open-in="popup"  data-searchbar="true" data-searchbar-placeholder="Поиск" data-popup-close-link-text="Выбрать">
    <select name="areas" multiple  popupCloseLinkText="Выбрать">
      <optgroup label="Фильтр по:">
        <option v-for="areas in this.$store.getters.AREA" :key='areas.id'  :value='areas.id'>{{areas.name_area}}</option>
      </optgroup>
    </select>
  </f7-list-item>
 
   <f7-list-item title="Отдел" id='dep'  class="item-link smart-select smart-select-init" data-open-in="popup"  data-searchbar="true" data-searchbar-placeholder="Поиск" data-popup-close-link-text="Выбрать">
    <select name="dep" multiple>
      <optgroup label="Фильтр по:">
        <option v-for="departments in this.$store.getters.DEPARTMENT" :key='departments.id'  :value='departments.id'>{{departments.name_department}}</option>
      </optgroup>
    </select>
  </f7-list-item>
 </f7-list>
</f7-page>  
</template>>    

<script>

export default {
 mounted() {
 this.$nextTick(function () {
        var smartSelect_areas =  this.$f7.smartSelect.get('#area');
        var smartSelect_departments =  this.$f7.smartSelect.get('#dep');
        let t = this
  
        smartSelect_areas.on(
          smartSelect_areas.setValue(t.$store.state.filter_areas) 
          // 'beforeOpen', function (el) {
            //  smartSelect_areas.setValue(t.$store.state.filter_areas) 
          // }  
        )          
        smartSelect_areas.on(
          'smartSelectClose', function (el) {
           let select_areas = [] 
           for (let i=0; i< el.selectEl.selectedOptions.length; i++){   
            select_areas.push(el.selectEl.selectedOptions[i].value)           
           }
             t.$store.state.filter_areas = select_areas
           }          
        )
        smartSelect_departments.on(
          smartSelect_departments.setValue(t.$store.state.filter_departments) 
          // 'beforeOpen', function (el) {
          //    smartSelect_departments.setValue(t.$store.state.filter_departments) 
          // }  
        )  
        smartSelect_departments.on(
          'smartSelectClose', function (el) {
           let select_departments =[]
           for (let i=0; i< el.selectEl.selectedOptions.length; i++){   
            select_departments.push(el.selectEl.selectedOptions[i].value)           
           }
           t.$store.state.filter_departments = select_departments                                 
        })  
 }) 
}
}

</script>