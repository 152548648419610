import {store} from './store';
import NotFoundPage from '../pages/404.vue';
import PhoneBook from '../pages/phonebook.vue';
import Contact from '../pages/contact.vue';
import EditContact from '../pages/edit-contact.vue'; 
import AddContact from '../pages/add-contact.vue';
import filter from '../pages/filter.vue'; 

  var routes= [
  {
    path: '/',
    async(routeTo, routeFrom, resolve, reject)
    {
      const router = this;
      if(store.getters.isLoggedIn)
      {
        resolve({
          component: PhoneBook,
        });
      }
      else
      {
        reject();				
      }
    }
  },
  {
    path: '/phonebook/',
    async(routeTo, routeFrom, resolve, reject)
    {
      const router = this;
      if(store.getters.isLoggedIn)
      {
        resolve({
          component: PhoneBook,
        });
      }
      else
      {
        reject();				
      }
    } 
  },

  {
    path: '/contact/:id/',
    name: 'contact',
    component: Contact,
    async(routeTo, routeFrom, resolve, reject)
    {
      const router = this;
      if(store.getters.isLoggedIn)
      {
        resolve({
          component: Contact,
        });
      }
      else
      {
        reject();				
      }
    }
  },

  {
    path: '/edit-contact/:id/',
    name: 'EditContact',
    component: EditContact,
    async(routeTo, routeFrom, resolve, reject)
    {
      const router = this;
      if(store.getters.isLoggedIn)
      {
        resolve({
          component: EditContact,
        });
      }
      else
      {
        reject();
      }
    }
  },

  {
    path: '/add-contact/:id/',
    name: 'AddContact',
    component: AddContact,
    async(routeTo, routeFrom, resolve, reject)
    {
      const router = this;
      if(store.getters.isLoggedIn)
      {
        resolve({
          component: AddContact,
        });
      }
      else
      {
        reject();
      }
    }
  },

  {
    path: '/filter/',
    async(routeTo, routeFrom, resolve, reject)
    {
      const router = this;
      if(store.getters.isLoggedIn)
      {
        resolve({
          component: filter,
        });
      }
      else
      {
        reject();				
      }
    } 
  },

  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;